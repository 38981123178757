import {IDropdownOption, TextField, Toggle} from "@fluentui/react";
import React from "react";

export interface UserDetailTextFieldsProps
{
    Username: string;
    Enabled: boolean;
    Email?: string;
    UpdateEmail: (newValue: string) => void;
    Attributes?: Record<string, string>;
    UpdateAmountOfTickets: (newValue: string) => void;
    UsernameReadOnly: boolean;
    UpdateUsername?: (newValue: string) => void;
    UpdateEnabled: (newValue: boolean) => void;
}


export const UserDetailTextFields = (props: UserDetailTextFieldsProps) => {
    return (
        <>
            <TextField value={props.Username} label="Name" disabled={props.UsernameReadOnly} onChange={(event) => props.UpdateUsername ? props.UpdateUsername(event.currentTarget.value) : undefined}/>
            <TextField value={props.Email} label="Email" onChange={(event) => props.UpdateEmail(event.currentTarget.value)}/>
            <Toggle label="Enabled" checked={props.Enabled} onText="On" offText="Off" onChange={(event, checked) => props.UpdateEnabled(checked ?? false)} />
        </>
    );
}

export const UPDATE_PASSWORD: string = "UPDATE_PASSWORD";

export const requiredActionOptions: IDropdownOption<string>[] = [
    { key: UPDATE_PASSWORD, text: "Update Password"}
]

