import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps, IDropdownOption,
    PrimaryButton,
    TextField
} from '@fluentui/react';
import * as Calls from './apiCalls';
import React, {useEffect, useState} from 'react';
import {EditUserModel, VideoOptionsModel} from "./models";
import {MultiSelect} from "../common/multiSelect";
import {requiredActionOptions, UserDetailTextFields} from "./common";

const dialogContentProps: IDialogContentProps =
{
    type: DialogType.normal,
    title: "User Detail",
}

interface DetailDialogProps
{
    Hidden: boolean;
    OnDismiss: () => void;
    SelectedUserId?: string;
}


const EditDialog = (props: DetailDialogProps) => {
    const [user, setUser] = useState<EditUserModel>();
    const [videoPermissions, setVideoPermissions] = useState<string[]>([]);
    const [videoOptions, setVideoOptions] = useState<IDropdownOption<string>[]>([]);

    const HandleSave = () => {
        Calls.SaveUserDetail(user, props.SelectedUserId).then(resp => {
            Calls.PostVideoPermissions({permissions: videoPermissions, userId: user?.id ?? ''})
                .then(() => props.OnDismiss());
        });
    }

    const UpdateEmail = (newValue: string) => {
        if(user)
        {
            setUser({...user, email: newValue});
        }
    }

    const UpdateEnabled = (newValue: boolean) => {
        if(user)
        {
            setUser({...user, enabled: newValue});
        }
    }
    const UpdateAmountOfTickets = (newValue: string) => {

        if (user)
        {
            let newAttributes = user.attributes ?? {};
            newAttributes["amount_of_tickets"] = newValue.toString();
            setUser( { ... user, attributes: newAttributes })
        }
    }

    const UpdateVideos = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        let newPermissions = item && item.selected ? [...videoPermissions, item.key as string] : videoPermissions.filter(key => key !== item?.key);
        setVideoPermissions(newPermissions);
    };

    useEffect(() => {
        if(!props.Hidden)
        {
            Calls.FetchUserDetail(props.SelectedUserId)
                .then(user => {
                    setUser(user.data);
                });
            Calls.FetchVideoPermissions(props.SelectedUserId ?? '')
                .then(resp => {
                    setVideoPermissions(resp.data);
                })
        }
    },[props.SelectedUserId])

    useEffect(() => {
        if(!props.Hidden)
        {
            Calls.FetchVideos()
                .then(videos => {
                    setVideoOptions(videos.data.map(x => ({ key: x.resourceKey, text: x.name })));
                });
        }
    },[props.SelectedUserId])
    return (
    <Dialog
        hidden={props.Hidden}
        onDismiss={props.OnDismiss}
        dialogContentProps={dialogContentProps}
        minWidth={400}>
        {
            user
            ?
                <>
                    <UserDetailTextFields Username={user.username} UsernameReadOnly={true} Email={user.email} UpdateEmail={UpdateEmail} Attributes={user.attributes} UpdateAmountOfTickets={UpdateAmountOfTickets} Enabled={user.enabled} UpdateEnabled={UpdateEnabled}/>
                    <MultiSelect Options={videoOptions} SelectedKeys={videoPermissions} OnChange={(event, item) => UpdateVideos(event, item)} Label="Allowed Videos"/>
                </>
            : <div>Loading...</div>
        }
        <DialogFooter>
            <DefaultButton onClick={props.OnDismiss}>Cancel</DefaultButton>
            <PrimaryButton onClick = {HandleSave} disabled={!user}>Save</PrimaryButton>
        </DialogFooter>
    </Dialog>
    );
}

export default EditDialog;