import React from "react";
import * as Calls from "./apiCalls";
import {DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton} from "@fluentui/react";

interface DeleteDialogProps
{
    UserId?: string;
    Hidden: boolean;
    OnDismiss: () => void;
}

const dialogContentProps: IDialogContentProps =
    {
        type: DialogType.normal,
        title: "Delete User",
    }

export const DeleteDialog = (props: DeleteDialogProps) => {
    const HandleDelete = () => {
        Calls.DeleteUser(props.UserId).then(result => result.status == 204 ? props.OnDismiss() : console.log("Error"));
    }
    return (
        <Dialog
            hidden={props.Hidden}
            onDismiss={props.OnDismiss}
            dialogContentProps={dialogContentProps}
            subText="Are you sure that you want to delete this user?"
            minWidth={400}>
            <DialogFooter>
                <DefaultButton onClick={props.OnDismiss}>Cancel</DefaultButton>
                <PrimaryButton onClick = {HandleDelete} >Ok</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}