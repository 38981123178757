import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {AppBar, Box, Button, styled, Toolbar} from "@mui/material";

interface NavbarProps
{
    logout: () => void;
}

const CustomNavbar = styled(AppBar)(({theme }) => ({
    background: theme.status.danger
}));


export const Navbar = (props: NavbarProps) => {
    return(
        <CustomNavbar
            position="fixed"
            color="default"
            elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Box
                    component="img"
                    sx={{
                        height: 60,
                    }}
                    alt="Your logo."
                    src="./logo-musicalia.png"
                />
                <Box
                    sx={{
                        flexGrow: 1
                    }}
                />
                <Button sx={{ color: 'white'}} onClick={() => navigateToVimeo()}>Synchronize Videos</Button>
                <Button sx={{ color: 'white'}} onClick={() => props.logout()}>Logout</Button>
            </Toolbar>
        </CustomNavbar>
    );
}

const navigateToVimeo = () => {
    let client_id = process.env.REACT_APP_VIMEO_CLIENT_ID;
    let redirect_uri = process.env.REACT_APP_VIMEO_REDIRECT_URI;
    let state = generateUniqueID();
    let scope_list = 'private';
    window.location.href = `${process.env.REACT_APP_VIMEO_AUTH_URI}?response_type=token&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=${scope_list}`
}

export default Navbar;