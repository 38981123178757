import axios, {AxiosResponse} from "axios";
import {GetKeycloakRequestHeaders, GetVimeoRequestHeaders} from "../../helpers/tokenHelpers";
import {VideoModel, VimeoVideosResponseModel} from "./models";

export const fetchVideos = ():Promise<AxiosResponse<VimeoVideosResponseModel>> =>  {
    return axios.get<VimeoVideosResponseModel>(process.env.REACT_APP_VIMEO_FETCH_VIDEOS_URI ?? '', { headers: GetVimeoRequestHeaders() })
}

export const SynchronizeVideos = (data?: VideoModel[]):Promise<AxiosResponse> => {
    return axios.post(`${process.env.REACT_APP_VIDEO_SYNC_BASE_ADDRESS}/admin/synchronize`, data, { headers: GetKeycloakRequestHeaders() })
}
