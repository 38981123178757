import {Dropdown, IDropdownOption} from "@fluentui/react";
import React from "react";

interface MultiSelectProps {
    Options: IDropdownOption<string>[]
    SelectedKeys: string[]
    OnChange: (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => void
    Label: string
}
export const MultiSelect = (props: MultiSelectProps) => {
    return (
        <Dropdown
            placeholder="Select options"
            options= { props.Options }
            label= { props.Label }
            selectedKeys={ props.SelectedKeys }
            onChange={ (event, item) => props.OnChange(event, item) }
            multiSelect
        />
    )
}