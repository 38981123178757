import React, {useEffect, useState} from 'react';
import {UserOverviewModel} from "./models";
import {Button, DetailsList, IColumn, IconButton, IIconProps, SelectionMode} from "@fluentui/react";
import * as Calls from './apiCalls';
import EditDialog from './editDialog';
import CreateDialog from "./createDialog";
import {DeleteDialog} from "./deleteDialog";
import {Box, Container, Grid, Input, InputAdornment, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";

const Overview = () => {
    const [items, setItems] = useState<UserOverviewModel[]>([]);
    const [filteredItems, setFilteredItems] = useState<UserOverviewModel[]>([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>('');

    const Columns: IColumn[] = [
        {key: "name", name: 'Name', fieldName: 'username', minWidth: 100 },
        {key: "actions", name: 'Actions', minWidth: 100, onRender: (item) => (
            <>
                <IconButton iconProps={ {iconName: 'Edit'} } onClick={() => OnEdit(item)}>Delete</IconButton>
                <IconButton iconProps={ {iconName: 'Delete'} } onClick={() => {
                    setSelectedUserId(item.id);
                    setShowDeleteDialog(true) }}>Delete</IconButton>
            </>
),
},
    ]

    const OnEdit = (item: UserOverviewModel) => {
        setSelectedUserId(item.id);
        setShowEditDialog(true);
    }

    const loadUserOverview = () => {
        // For some unknown reason, this call fails the first time after logging in.
        // Retrying fixes this issue.
        // TODO: find the cause and fix properly.
        Calls.FetchUsers().then(resp => {
            setItems(resp.data);
        }).catch(() =>
            Calls.FetchUsers().then(resp => {
            setItems(resp.data);
        }));
    }

    useEffect(() => {
        loadUserOverview();
    }, []);

    useEffect(() => {
        setFilteredItems(searchValue ? items?.filter(i => i.username.includes(searchValue)) ?? [] : items);
    },[searchValue, items]);

    return (
        <>
            <Container maxWidth="md">
                <Box component="div" sx={{  overflow: 'auto'}}>
                    <Grid container spacing={2} sx={{ mt: '10px'}}>
                        <Grid item sx={{ flexGrow: 1}}>
                            <Typography variant="subtitle1" component="div" gutterBottom>
                                All users
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Input
                                id="input-with-icon-adornment"
                                placeholder= "Search user"
                                value={searchValue}
                                inputProps={{ style: { fontSize: 15}}}
                                onChange={(event) => setSearchValue(event.target.value)}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                    <DetailsList
                        items={filteredItems ?? []}
                        columns={Columns}
                        selectionMode={SelectionMode.none}
                    />
                </Box>
            </Container>
                <EditDialog SelectedUserId={selectedUserId} Hidden={!showEditDialog} OnDismiss={() => setShowEditDialog(false)}  />
                <DeleteDialog UserId={selectedUserId} Hidden={!showDeleteDialog} OnDismiss={() => {
                    setShowDeleteDialog(false);
                    loadUserOverview();
                }
                }  />
                <CreateDialog Hidden={!showCreateDialog} OnDismiss={() => {
                    setShowCreateDialog(false);
                    loadUserOverview();
                }}  />
        </>
    );
};

export default Overview;
