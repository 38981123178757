import { Link } from "react-router-dom";
import {fetchAndStoreVimeoToken, getVimeoTokenFromCallbackUrl} from "../../helpers/tokenHelpers";
import {fetchVideos, SynchronizeVideos} from "./apiCalls";
import {useEffect} from "react";
import {Loading} from "../common/loading";

export const CallbackComponent = () => {
    useEffect(() => {
        fetchAndStoreVimeoToken();
        fetchVideos()
            .then(res => {
                SynchronizeVideos(res.data.data)
                    .then(() => document.location = "/");
            });
    })

    return (
        <Loading></Loading>
    )
}

