import './App.css';
import keycloak from "./features/auth/keycloak";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import Overview from "./features/user/overview";
import Navbar from "./features/nav/navbar";
import {initializeIcons} from "@fluentui/react";
import {CallbackComponent} from "./features/vimeo/callback";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {CacheKeycloakTokens} from "./helpers/tokenHelpers";
import {Box, createTheme, CssBaseline, GlobalStyles, ThemeProvider} from "@mui/material";
import {pink} from "@mui/material/colors";
import {Loading} from "./features/common/loading";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const Test = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'sans-serif'
            ].join(','),
        },
        status: {
            danger:'#ff1053',
        },
    });
    return (
        <>
            <ReactKeycloakProvider authClient={keycloak} onTokens={CacheKeycloakTokens} LoadingComponent ={Loading()} initOptions={{ onLoad: "login-required"}}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <CssBaseline />
                    <Box height="100vh" display={"flex"} >
                        <Navbar logout={keycloak.logout}></Navbar>
                        <Box sx={{overflow: 'auto', width: "100%", 'margin-top':"80px"}} >
                            <Overview />
                        </Box>
                    </Box>
                </ThemeProvider>
            </ReactKeycloakProvider>
        </>
    )
}

function App() {
    initializeIcons();
    return (
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Test />} />
                        <Route path="/vimeocallback" element={<CallbackComponent />} />
                    </Routes>
                </BrowserRouter>
    );
}


export default App;