import {Button, DefaultButton, Dialog, DialogFooter, PrimaryButton} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import { CreateUserModel } from "./models";
import * as Calls from "./apiCalls";
import {UPDATE_PASSWORD, UserDetailTextFields} from "./common";

interface CreateDialogProps {
    OnDismiss: () => void;
    Hidden: boolean;
}


const CreateDialog = (props: CreateDialogProps) => {
    const initialUser = {
        username: '',
        email: '',
        attributes: {},
        requiredActions: [UPDATE_PASSWORD],
        videos: [],
        enabled: true
    };
    const [user, setUser] = useState<CreateUserModel>(initialUser);

    const [errorMsg, setErrorMsg] = useState<string>();

    const HandleSave = () => {
        Calls.CreateUser(user)
            .then(() => props.OnDismiss())
            .then(() => setUser(initialUser))
            .catch(err => setErrorMsg(JSON.parse(err.request.response).errorMessage));
    }

    const UpdateEmail = (newValue: string) => {
        if(user)
        {
            setUser({...user, email: newValue});
        }
    }

    const UpdateEnabled = (newValue: boolean) => {
        if(user)
        {
            setUser({...user, enabled: newValue});
        }
    }

    const UpdateAmountOfTickets = (newValue: string) => {

        if (user)
        {
            let newAttributes = user.attributes ?? {};
            newAttributes["amount_of_tickets"] = newValue.toString();
            setUser( { ... user, attributes: newAttributes })
        }
    }

    const UpdateUserName = (newValue: string) => {
        if (user)
        {
            setUser( { ...user, username: newValue })
        }
    }

    return (
        <Dialog
            hidden={props.Hidden}
            onDismiss={props.OnDismiss}
            minWidth={400}>
            <UserDetailTextFields Username={user.username} UsernameReadOnly={false} Email={user.email} UpdateEmail={UpdateEmail} Attributes={user.attributes} UpdateAmountOfTickets={UpdateAmountOfTickets} UpdateUsername={UpdateUserName} Enabled={user.enabled} UpdateEnabled={UpdateEnabled}/>
            {
                errorMsg ? <p>{errorMsg}</p> : undefined
            }
            <DialogFooter>
                <DefaultButton onClick={() => {
                    setUser(initialUser);
                    props.OnDismiss();
                }}>Cancel</DefaultButton>
                <PrimaryButton onClick = {HandleSave} disabled={!user}>Save</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}

export default CreateDialog;