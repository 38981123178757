const keycloakTokenStorageKey = "keycloak_token";
const vimeoTokenStorageKey = "vimeo_token";

export interface VimeoTokenResult
{
    access_token?: string;
}

export interface KeycloakTokenResult {
    idToken?: string,
    token?: string,
    refreshToken?: string
}

export const fetchAndStoreVimeoToken = () =>
{
    let result = getVimeoTokenFromCallbackUrl(window.location.toString().split('#')[1]);
    CacheVimeoTokens(result);
}

export const getVimeoTokenFromCallbackUrl = (query?: string): VimeoTokenResult => {
    const result: Record<string, string> = {};
    new URLSearchParams(`${query}`||window.location.search).forEach((value, key) => {
        result[key] = value;
    });
    return { access_token: result["access_token"]};
}

export function GetKeycloakRequestHeaders():Record<string,string>
{
    return GetRequestHeaders(keycloakTokenStorageKey);
}

export function GetVimeoRequestHeaders():Record<string, string>
{
    return GetRequestHeaders(vimeoTokenStorageKey);
}

export function GetRequestHeaders(storageKey: string):Record<string,string>
{
    return {
        "Authorization": `Bearer ${localStorage[storageKey]}`,
        "Content-type": "application/json",
    }
}

export function CacheKeycloakTokens(token: KeycloakTokenResult) {
    localStorage[keycloakTokenStorageKey] = token.token;
}

export function CacheVimeoTokens(token: VimeoTokenResult) {
    localStorage[vimeoTokenStorageKey] = token.access_token;
}
